import React from 'react';
import { Hero, Heading } from 'react-bulma-components';

const HeroHome = () => {
	return (
		<Hero size="medium">
			<Hero.Body
				style={{
					backgroundImage: 'url("../../assets/gigi-marie-hero-2.png")',
					backgroundSize: 'cover',
					backgroundPosition: 'center',
				}}
			>
				<Heading className="is-family-secondary" textColor="white" textAlign="center" textSize={1} weight="light">
					GiGi Marie, Intuitive Coach
				</Heading>
				<Heading subtitle textColor="white" textAlign="center" textSize={3} weight="light">
					As an intuitive coach and retreat facilitator, Gigi Marie creates a space that provides grounding, support and
					healing for anyone seeking a healthier partnership with themselves.
				</Heading>
			</Hero.Body>
		</Hero>
	);
};

export default HeroHome;
