import React from 'react';
import { Button } from 'react-bulma-components';
import { HashLink } from 'react-router-hash-link';

const ScrollToTop = ({ visible }) => {
	return (
		<Button
			renderAs={HashLink}
			smooth
			to="#top"
			color="info"
			size="small"
			className={`ScrollToTop${visible ? ' visible' : ''}`}
			p={2}
		>
			<span className="material-symbols-outlined">expand_less</span>
		</Button>
	);
};

export default ScrollToTop;
