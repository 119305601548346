import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bulma-components';

const BackButton = () => {
	return (
		<Button color="info" size="medium" m={1} renderAs={Link} to="/" textWeight="light">
			Back to Home
		</Button>
	);
};

export default BackButton;
