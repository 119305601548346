import React from 'react';
import { Button } from 'react-bulma-components';

const SwiperButton = ({ direction, onClick, className, style }) => {
	return (
		<Button color="primary" size="small" onClick={onClick} className={className} p={2}>
			{direction === 'right' ? (
				<span className="material-symbols-outlined">chevron_right</span>
			) : (
				<span className="material-symbols-outlined">chevron_left</span>
			)}
		</Button>
	);
};

export default SwiperButton;
