import React, { useState, useRef } from 'react';
import emailjs from 'emailjs-com';
import { Section, Button, Heading, Content, Form } from 'react-bulma-components';

const Retreats = () => {
	const [formData, setFormData] = useState({ first_name: '', last_name: '', email: '', phone: '' });
	const [submitted, setSubmitted] = useState(false);
	const [valid, setValid] = useState({ first_name: null, last_name: null, email: null, phone: null });

	const form = useRef();

	const onChange = (e) => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const onSubmit = async (e) => {
		e.preventDefault();

		if (
			!form.current.elements[0].validity.valid ||
			!form.current.elements[1].validity.valid ||
			!form.current.elements[2].validity.valid ||
			!form.current.elements[3].validity.valid
		) {
			setValid({
				...valid,
				first_name: form.current.elements[0].validity.valid,
				last_name: form.current.elements[1].validity.valid,
				email: form.current.elements[2].validity.valid,
				phone: form.current.elements[3].validity.valid,
			});
		} else {
			await emailjs.sendForm('service_n7lii4k', 'template_3zaf80p', form.current, 'GtzzUxJO3dqza82Bb');
			setFormData({ first_name: '', last_name: '', email: '', phone: '' });
			setSubmitted(true);
		}
	};

	return (
		<Section>
			<Heading
				className="is-family-secondary"
				textColor="primary"
				textSize={2}
				mobile={{ textAlign: 'center' }}
				tablet={{ textAlign: 'left' }}
			>
				Retreats
			</Heading>

			{submitted ? (
				<Content renderAs="p" textSize={5}>
					Thank you for submitting your contact information. GiGi will reach out to you when the next retreat is
					scheduled.
				</Content>
			) : (
				<>
					<Content renderAs="p" textSize={5}>
						To receive information on healing retreats or cancer connection retreats in the Rocky mountain region,
						please share your information below.
					</Content>

					<form onSubmit={onSubmit} ref={form} noValidate>
						<Form.Field className="is-horizontal">
							<Form.Field className="field-body">
								<Form.Field>
									<Form.Label htmlFor="first_name">First name</Form.Label>
									<Form.Input
										required
										id="first_name"
										name="first_name"
										value={formData.first_name}
										onChange={onChange}
									/>
									{valid.first_name === false && <Form.Help color="info">Please enter your first name</Form.Help>}
								</Form.Field>

								<Form.Field>
									<Form.Label htmlFor="last_name">Last name</Form.Label>
									<Form.Input required id="last_name" name="last_name" value={formData.last_name} onChange={onChange} />
									{valid.last_name === false && <Form.Help color="info">Please enter your last name</Form.Help>}
								</Form.Field>
							</Form.Field>
						</Form.Field>

						<Form.Field className="is-horizontal">
							<Form.Field className="field-body">
								<Form.Field>
									<Form.Label htmlFor="email">Email</Form.Label>
									<Form.Input
										required
										id="email"
										type="email"
										name="email"
										value={formData.email}
										onChange={onChange}
									/>
									{valid.email === false && <Form.Help color="info">Please enter a valid email address</Form.Help>}
								</Form.Field>

								<Form.Field>
									<Form.Label htmlFor="phone">Phone number</Form.Label>
									<Form.Input required id="phone" name="phone" value={formData.phone} onChange={onChange} />
									{valid.phone === false && <Form.Help color="info">Please provide a valid phone number</Form.Help>}
								</Form.Field>
							</Form.Field>
						</Form.Field>

						<Button color="info" size="medium" textWeight="light">
							Submit
						</Button>
					</form>
				</>
			)}
		</Section>
	);
};

export default Retreats;
