import React, { useEffect, useState } from 'react';
import { Section, Columns, Content, Image } from 'react-bulma-components';

import ScheduleButton from './ScheduleButton';

const Offering = () => {
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		};
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return (
		<Section className="IntuitiveCoaching" id="intuitive-coaching">
			<Columns vCentered>
				<Columns.Column>
					<Image
						src={
							windowWidth <= 769
								? '../assets/gigi-marie-coaching-mobile-2.png'
								: '../../assets/gigi-marie-coaching-3.png'
						}
					/>
				</Columns.Column>

				<Columns.Column tablet={{ size: 'two-thirds' }} desktop={{ size: 'three-quarters' }}>
					<Content renderAs="p" textSize={5}>
						Internal work is a practice. Through intuitive coaching sessions, GiGi welcomes individuals to share their
						life stories. The process of sharing invites patterns to emerge. Just as patterns surround us in the natural
						world—symmetries, spirals, waves—patterns also emerge from within, teaching us lessons.
					</Content>

					<Content renderAs="p" textSize={5}>
						Some patterns repeat themselves over and over, requiring awareness in order to break free from the “what
						ifs,” the negative self-talk, the crippling fear, the second guessing of our dreams, and the “trying to do
						it all by ourselves.” When we recognize our patterns and understand ourselves better, we begin to feel
						liberated and find tremendous peace within. That understanding also frees us from the expectations of
						others, allowing us to be our authentic selves. Life is filled with big and small transitions, as well as
						planned and unplanned events. Transition is an invitation to wake up and invite something already alive
						within us into consciousness. In the East, many say that no matter how deeply asleep we may be, we will all
						“wake up to die.” My hunch is that waking earlier is better. If you would like help tapping into your “inner
						knowing,” my practice is ongoing and I would be honored to hold space for you.
					</Content>

					<Content renderAs="p" textSize={5}>
						I work with individuals 1:1 over a period of time and offer retreats for small groups of individuals who
						might benefit from a group setting.
					</Content>

					<ScheduleButton service="an appointment" />
				</Columns.Column>
			</Columns>
		</Section>
	);
};

export default Offering;
