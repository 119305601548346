import React, { useEffect, useState } from 'react';
import { useLocation, Routes, Route } from 'react-router-dom';

import Header from './Header';
import HeroHome from './Hero';
import About from './About';
import Offering from './Offering';
import ClientImpact from './ClientImpact';
import Calendly from './Calendly';
import Retreats from './Retreats';
import PaddleOutCeremony from './PaddleOutCeremony';
import FooterComponent from './Footer';
import ScrollToTop from './ScrollToTop';

import '../../public/styles.css';

const App = () => {
	const [scrollPercentage, setScrollPercentage] = useState(0);

	let location = useLocation();

	useEffect(() => {
		let vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', `${vh}px`);
	}, []);

	useEffect(() => {
		function handleScroll() {
			const windowHeight = window.innerHeight;
			const documentHeight = document.documentElement.scrollHeight;
			const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
			const scrollHeight = documentHeight - windowHeight;
			const percent = (scrollTop / scrollHeight) * 100;
			setScrollPercentage(percent.toFixed(2));
		}

		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, []);

	useEffect(() => {
		if ((location.hash.slice(1) === 'top' || !location.hash) && location.pathname === '/') {
			document.title = 'GiGi Marie Healing';
		}
	}, [location]);

	return (
		<div className="App">
			<Header />

			<main>
				<Routes>
					<Route
						path="/"
						element={
							<>
								<HeroHome />

								<Offering />

								<ClientImpact />

								<About />

								<Calendly />

								<Retreats />
							</>
						}
					/>
					<Route path="/paddle-out-ceremony" element={<PaddleOutCeremony />} />
				</Routes>
			</main>

			<ScrollToTop visible={scrollPercentage > 50} />
			<FooterComponent />
		</div>
	);
};

export default App;
