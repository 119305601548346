import React from 'react';
import { HashLink } from 'react-router-hash-link';
import { Button } from 'react-bulma-components';

const ScheduleButton = ({ service }) => {
	return (
		<Button renderAs={HashLink} smooth to="/#schedule" color="info" size="medium" m={1} textWeight="light">
			Schedule {service}
		</Button>
	);
};

export default ScheduleButton;
