import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { Navbar, Image, Button } from 'react-bulma-components';

const Header = () => {
	const [burgerMenuOpen, setBurgerMenuOpen] = useState(false);

	const handleClick = (e) => {
		if (e.target.className === 'navbar-burger') {
			setBurgerMenuOpen(true);
		} else {
			setBurgerMenuOpen(false);
		}
	};

	return (
		<Navbar role="navigation" active={burgerMenuOpen ? true : false}>
			<Navbar.Brand>
				<Navbar.Item renderAs={HashLink} to="/#top" smooth>
					<img src="../../assets/gigi-marie-horizontal-transparent.png" />
				</Navbar.Item>

				<Navbar.Burger onClick={handleClick} />
			</Navbar.Brand>

			<Navbar.Menu>
				<Navbar.Container>
					<Navbar.Item renderAs={HashLink} smooth to="/#intuitive-coaching" onClick={handleClick}>
						Intuitive Coaching
					</Navbar.Item>

					<Navbar.Item renderAs={HashLink} smooth to="/#client-impact" onClick={handleClick}>
						Client Impact
					</Navbar.Item>

					<Navbar.Item renderAs={HashLink} smooth to="/#about" onClick={handleClick}>
						About Marie
					</Navbar.Item>
				</Navbar.Container>

				<Navbar.Container align="right">
					<Navbar.Item renderAs={HashLink} smooth to="/#schedule" onClick={handleClick}>
						Schedule
					</Navbar.Item>
				</Navbar.Container>
			</Navbar.Menu>
		</Navbar>
	);
};

export default Header;
