import React, { useEffect } from 'react';
import { Section, Columns, Image, Heading, Content, Level } from 'react-bulma-components';

import BackButton from './BackButton';

const PaddleOutCeremony = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<Section>
			<Heading className="is-family-secondary" renderAs="h1" textColor="primary" textSize={1} textAlign="center">
				Paddle Out Ceremony
			</Heading>

			<Columns vCentered>
				<Columns.Column desktop={{ size: 'one-third' }} tablet={{ size: 'one-half' }}>
					<Image src="../../assets/paddle-out-ceremony.png" className="PaddleOutCeremony-image" />
				</Columns.Column>
				<Columns.Column>
					<Content renderAs="p" textSize={5}>
						Shortly after being diagnosed with breast cancer and having a double mastectomy, I took a vacation to the
						beach. For months, I was picturing myself walking on warm sand and gentle waves. From my hospital bed, I
						could smell the salt air and feel the sea breeze. When the anesthesiologist encouraged me to picture a
						calming place, I chose the beach. I saw myself In the ocean, riding the waves. The day finally came when my
						daughter, Kate, and I boarded a plane and arrived in sunny California. Being at the water's edge left me
						feeling washed out. I sat on my beach chair in the sand, breathing ocean air. I spent the day watching
						resilient surfers. I've always been fascinated by their ability to ride a wave of water. Nothing short of
						magic. The next day with the encouragement of my daughter, I was determined to walk along the water's edge.
						As we walked, I felt the wave of despair, disappointment, and uncertainty. Trying to fight back my tears, we
						happened upon a floating memorial held in the ocean, a few yards from the shore, where surfers were honoring
						the life of a loved one who had passed. I have so many memories of how the ocean brings people together, but
						an ocean-based ceremony was a first for me. This ritual had an ancient feel. It was an honor to watch from a
						short distance. This walk gave Kate and I an opportunity to talk about my last wishes. I love the ocean
						unconditionally. I trust the ebb and flow of the ocean. Now more than ever, I need to trust the ebb and flow
						of my life. I need to remember what resilience looks like. I need to be reminded that I am not alone. Riding
						the waves of the ocean is a lot like riding the waves of life. I do have a new set of circumstances, which
						bring their own set of challenges, but I have been here before. There are natural ebbs and flows in life;
						lessons that we learn again and again. I don't like the “ebb” phase when I feel like life is trying to
						overwhelm me. Just like being in the ocean, I have struggled against the current. But being in the ocean
						also reminds me that life is not permanent, and if I can surrender to the “ebb,” the current will begin to
						shift, as it always does. Sometimes life flows towards our hopes and dreams, and sometimes it flows away,
						giving us time for solitude and to rejuvenate and heal.
					</Content>
				</Columns.Column>
			</Columns>

			<Level>
				<Level.Item>
					<BackButton />
				</Level.Item>
			</Level>
		</Section>
	);
};

export default PaddleOutCeremony;
