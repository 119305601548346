import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { InlineWidget } from 'react-calendly';
import { Section, Heading } from 'react-bulma-components';

const Calendly = () => {
	let location = useLocation();

	useEffect(() => {
		if (location.hash.slice(1) === 'schedule') {
			document.title = 'Schedule a Service | GiGi Marie Healing';
		}
	}, [location.hash]);

	return (
		<Section className="Calendly" id="schedule" backgroundColor="primary">
			<Heading
				className="is-family-secondary"
				renderAs="h2"
				mobile={{ textAlign: 'center' }}
				tablet={{ textAlign: 'right' }}
				textColor="white"
				textSize={2}
			>
				Schedule Intuitive Coaching
			</Heading>

			<InlineWidget
				url="https://calendly.com/gigimariehealing/intuitive-coaching"
				styles={{ height: 665 }}
				pageSettings={{
					primaryColor: '#72668a;',
					backgroundColor: '#f5f5f5',
					textColor: '#4a4a4a',
				}}
			/>
		</Section>
	);
};

export default Calendly;
