import React, { useEffect, useState } from 'react';
import { Section, Heading } from 'react-bulma-components';
import axios from 'axios';

import Carousel from './Carousel';

const ClientImpact = () => {
	const [testimonials, setTestimonials] = useState([]);

	useEffect(() => {
		const getTestimonials = async () => {
			const response = await axios.get('/api/testimonials');
			setTestimonials(response.data);
		};

		getTestimonials();
	}, []);

	return (
		<Section className="ClientImpact" id="client-impact">
			<Heading className="is-family-secondary" renderAs="h2" textAlign="center" textColor="white" textSize={2}>
				Client Impact
			</Heading>

			<Carousel testimonials={testimonials} />
		</Section>
	);
};

export default ClientImpact;
