import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Heading, Image, Section, Columns, Content, Button } from 'react-bulma-components';

export const AboutAvatar = () => {
	return (
		<Columns.Column className="About-avatar">
			<Image
				src="../../assets/marie.jpeg"
				alt="Candid photo of GiGi Marie standing next to a tree, smiling and laughing"
			/>
		</Columns.Column>
	);
};

export const AboutInfo = () => {
	return (
		<Columns.Column className="About-info" tablet={{ size: 'two-thirds' }} desktop={{ size: 'three-quarters' }}>
			<Content renderAs="p" textSize={5}>
				For more than thirty years, I have accompanied people through the bookends of life as a birth and end of life
				doula and everything in-between: as a intuitive coach, energy healer, Rolfer, Massage Therapist, Sound
				Meditation practitioner and SoulCollege facilitator. I hold space with an open-hearted presence.
			</Content>

			<Heading className="is-family-secondary" textColor="link" textSize={4}>
				A sudden turn of events // Life on life's terms
			</Heading>

			<Content renderAs="p" textSize={6}>
				Never in a million years does anyone expect to hear the words “you have cancer.” You would think the most
				difficult question anyone ever asked me was: Are you prepared to die? But truly the most difficult question was
				the one I ask myself. It was: Can you reimagine a life on a new path toward greater energy, purpose, and health?
			</Content>

			<Content renderAs="p" textSize={6}>
				Mine is a journey where betrayal, loss, despair, and illness taught me that how we treat ourselves when we are
				sick, lonely, desperate, and broken really matters.
			</Content>

			<Content renderAs="p" textSize={6}>
				As a cancer patient, I eventually learned to hold that same open-hearted space, that I spent a lifetime holding
				for others, for myself. I learned that loneliness, isolation, fear, regret, stress, and anxiety can be
				transformed into joy, love, acceptance, and healing even while navigating a scary diagnosis and treatment. In a
				moment of clarity, I reawaken the dormant guru, the healer, the intuitive coach. Through coaching, I invite you
				to quiet your restless mind, let your soul find its voice, and reawaken the parts of yourself that lie dormant.
				My work these days is 90 percent coaching, because I know firsthand the value of being heard, seen, and
				celebrated. The rest of the time, I practice energy healing and sound meditation.
			</Content>

			<Content renderAs="p" textSize={6}>
				I look forward to hearing from you. If you have any questions or comments, please feel free to reach out to me.
			</Content>

			<Content textSize={5}>
				<Link to="/paddle-out-ceremony">
					Read GiGi's personal journal entry from a few months after being diagnosed with cancer.
				</Link>
			</Content>
		</Columns.Column>
	);
};

const About = () => {
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	let location = useLocation();

	useEffect(() => {
		if (location.hash.slice(1) === 'about') {
			document.title = 'About GiGi Marie | GiGi Marie Healing';
		}
	}, [location.hash]);

	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		};

		window.scrollTo(0, 0);
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return (
		<Section className="About" id="about">
			<Heading
				className="is-family-secondary"
				mobile={{ textAlign: 'center' }}
				tablet={{ textAlign: 'left' }}
				textColor="primary"
				textSize={2}
			>
				About GiGi Marie
			</Heading>
			<Columns vCentered={true}>
				{windowWidth <= 769 ? (
					<>
						<AboutAvatar /> <AboutInfo />
					</>
				) : (
					<>
						<AboutInfo />
						<AboutAvatar />
					</>
				)}
			</Columns>
		</Section>
	);
};

export default About;
