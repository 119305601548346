import React from 'react';
import { Container } from 'react-bulma-components';
import Slider from 'react-slick';
import { v4 as uuidv4 } from 'uuid';

import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';

import CarouselItem from './CarouselItem';
import SwiperButton from './SwiperButton';

const Carousel = ({ testimonials }) => {
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		// autoplay: true,
		autoplaySpeed: 5000,
		adaptiveHeight: true,
		pauseOnHover: true,
		pauseOnFocus: true,
		nextArrow: <SwiperButton direction="right" />,
		prevArrow: <SwiperButton direction="left" />,
	};

	return (
		<Container p={2}>
			<Slider {...settings}>
				{testimonials.map((testimonial, idx) => {
					return (
						<CarouselItem
							name={testimonial.name}
							service={testimonial.service}
							quote={testimonial.quote}
							years={testimonial.years}
							imageName={testimonial.imageName}
							key={uuidv4()}
						/>
					);
				})}
			</Slider>
		</Container>
	);
};

export default Carousel;
