import React from 'react';
import { Footer, Level } from 'react-bulma-components';

const FooterComponent = () => {
	return (
		<Footer backgroundColor="success">
			<Level>
				<Level.Side textColor="light" textSize={5} textWeight="light" align="left">
					© 2023 GiGi Marie
				</Level.Side>
				<Level.Side textColor="light" textSize={5} textWeight="light" align="right">
					gigimariehealing@gmail.com
				</Level.Side>
			</Level>
		</Footer>
	);
};

export default FooterComponent;
