import React from 'react';
import { Image, Content, Columns } from 'react-bulma-components';

const CarouselItem = ({ name, quote, years, imageName }) => {
	return (
		<Columns className="CarouselItem" mx={6} my={2} vCentered>
			{imageName && (
				<Columns.Column>
					<Image src={`../../assets/${imageName}`} />
				</Columns.Column>
			)}

			<Columns.Column size={imageName ? 'four-fifths' : 'full'} className="CarouselItem-quote">
				<Content renderAs="p" textSize={5} marginless>
					“{quote}”
				</Content>

				<Content className="is-family-secondary" marginless textAlign="right" textSize={5}>
					—{name}
					{years && ` (${years})`}
				</Content>
			</Columns.Column>
		</Columns>
	);
};

export default CarouselItem;
